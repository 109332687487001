<template>
  <div class="circle-details-container">
    <div class="circle-header">
      <div class="circle-info">
        <span class="subject">{{ detailObj.title }}</span>
        <Button
          class="follow"
          v-if="detailObj.flag"
          v-throttleClick
          @click="onFocus(detailObj.id, true)"
          >已关注</Button
        >
        <Button
          class="follow"
          v-else
          @click="onFocus(detailObj.id)"
          v-throttleClick
          >关注</Button
        >
        <div class="content">
          {{ detailObj.detail }}
        </div>
      </div>
    </div>
    <div class="main">
      <ul class="nav">
        <li>
          <router-link
            :to="{ path: '/detaildiscuss', query: { id: $route.query.id } }"
            >讨论</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              path: '/detailachievements',
              query: { id: $route.query.id },
            }"
            >成果</router-link
          >
        </li>
      </ul>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    this.circleDetail({
      id: this.$route.query.id,
    });
  },
  data() {
    return {
      detailObj: {},
    };
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  methods: {
    async onFocus(id, status = false) {
      if (this.hasLogin) {
        // 1 关注 0 取消关注
        let data = {
          attentionId: id,
          type: status ? 0 : 1,
        };
        const resp = await this.$apis.circleServe.onFocus(data);
        if (resp.resultDesc === "成功!") {
          this.circleDetail({ id });
          status
            ? this.$Message.success("取消关注成功")
            : this.$Message.success("关注成功");
        } else {
          this.$Message.error("关注失败");
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    async circleDetail(data) {
      const resp = await this.$apis.circleServe.circleDetail(data);
      this.detailObj = resp.data;
      document.title = this.detailObj.title + "-百家在线";
    },
  },
};
</script>

<style lang="scss" scoped>
.circle-details-container {
  background: #ffffff;
  width: 100%;
  min-height: 1000px;
  .circle-header {
    width: 100%;
    min-width: 1440px;
    height: 300px;
    background-image: url("~@/assets/qietu/circlebg.png");
    background-size: 100% 100%;
    margin: 0 auto;
    .circle-info {
      width: 1440px;
      margin: 0 auto;
      color: #ffffff;
      padding-top: 87px;
      .subject {
        margin-top: 87px;
        font-size: 50px;
        margin-right: 24px;
        vertical-align: middle;
      }
      .follow {
        display: inline-block;
        width: 137px;
        height: 38px;
        background: #00a4ff;
        border-radius: 19px;
        text-align: center;
        line-height: 38px;
        vertical-align: middle;
        cursor: pointer;
        border: none;
        color: #fff;
      }
      .content {
        margin-top: 25px;
        font-size: 12px;
        width: 792px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
  .main {
    width: 1440px;
    margin: 0 auto;
    .nav {
      width: 1040px;
      display: flex;
      margin-top: 40px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
      li {
        width: 160px;
        text-align: center;
        font-size: 18px;
        white-space: nowrap;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        .router-link-exact-active.router-link-active {
          color: #00a4ff;
          &::after {
            content: "";
            display: block;
            width: 160px;
            height: 8px;
            background-image: url("~@/assets/imgs/xz.png");
            background-size: 100% 100%;
          }
        }
        a {
          color: #333;
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
